import React from 'react';

import { IntlProvider } from 'react-intl';
import { isSalonzone } from '../utils';
import messages from '../data/messages/cs';
import salonzoneMessages from '../data/messages/salonzone/cs';

import Claim from '../marketing/landing/components/common/pages/claim';
import FontSelector from '../components/FontSelector';

const Page = ({ location }) => (
  <IntlProvider
    locale="cs"
    messages={isSalonzone ? salonzoneMessages : messages}
  >
    <FontSelector>
      <Claim location={location} />
    </FontSelector>
  </IntlProvider>
);

export default Page;
